<template>
  <b-card
    title="S-LCA"
    class="col-4 offset-md-4">
    <p>Insert your email to recover password</p>
    <b-form
      @submit="onSubmit">
      <b-form-input v-model="email" placeholder="Email" class="mb-3"></b-form-input>

      <b-button type="submit" variant="success">Recover</b-button>

      <b-alert :show="success" variant="success" class="mt-3">Check your email</b-alert>
    </b-form>
  </b-card>
</template>

<script>
export default {
  name: 'Home',
  data: function () {
    return {
      email: '',
      success: false
    }
  },
  methods: {
    async onSubmit (evt) {
      evt.preventDefault();

      try {
        await this.$auth.sendPasswordResetEmail(this.email, {
          url: 'https://slca.lcengineering.eu/',
          handleCodeInApp: false
        })
      } catch (e) {
        console.log(e)
      }

      this.success = true
    }
  }
}
</script>

